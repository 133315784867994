import React from 'react';

function About() {
    return (
        <div class="about appear__animation" id="about">
            <div className="title"><p>1.</p>About Me</div>
            <div className="split__line">
                <div className="about__text">
                    <p className="small__text">Hello and welcome to my portfolio website! My name is Koral Kulacoglu, and I am a software engineering student at the University of Waterloo. My passion lies in innovation through creativity. From time to time, I enjoy exploring the field of AI through <a className="link__text" href="#projects">projects</a> and <a className="link__text" href="#publications">research papers</a>.</p>
                    &nbsp;
                    <p className="small__text">Driven by my passion for AI and innovation, I have accomplished numerous remarkable feats such as winning the 2022 Canada-Wide Science Fair <a className="link__text" href="https://projectboard.world/ysc2022/project/foursight-analysis-of-cancerous-genetic-profiles-with-artificial-neural-networks" target="_blank" rel="noreferrer">(CWSF)</a>, and bringing team Canada the second place medal in the 33rd EU Contest for Young Scientists <a className="link__text" href="https://youthscience.ca/three-canadian-students-take-top-prize-at-eucys/" target="_blank" rel="noreferrer">(EUCYS)</a>.</p>
                    &nbsp;
                    <p className="small__text">I also love doing competitive programming competitions and CTFs. I upload some of my live participations on my <a className="link__text" href="https://www.youtube.com/@koralkulacoglu768" target="_blank" rel="noreferrer">YouTube channel</a>. Enjoy exploring my website!</p>
                </div>
                <img className="photo" src="https://i.ibb.co/X4SzVBs/kkimage.png" alt="Me"></img>
            </div>
        </div>
    )
}

export default About