import React from 'react';
import { FaLink } from "react-icons/fa";

function Publications() {
    return (
        <div className="publications reveal" id="publications">
            <div className="title"><p>5.</p>Publications</div>
            <p className="small__text">These are some of my research papers. I linked the LaTeX file for each of them.</p>
            &nbsp;
            <p className="small__text"><a href='https://www.overleaf.com/read/wnpwghjkgpgc#a4479e' target="_blank" rel="noreferrer" className="paper__link"><FaLink/></a> Kulacoglu, K. & Wu, H. (2022). <i>FourSight: Analysis of Cancerous Genetic Profiles with Artificial Neural Networks</i>.</p>
            &nbsp;
            <p className="small__text"><a href='https://www.overleaf.com/read/rxntpctkzbxt#30afc7' target="_blank" rel="noreferrer" className="paper__link"><FaLink/></a> Kulacoglu, K. & Tan, I. (2021). <i>Cancer Detection with Optimized Neural Networks</i>.</p>
        </div>
    )
}

export default Publications;