import React, { useState } from 'react';
// import {FaSchool} from "react-icons/fa"

function Education() {
    const [selectedItem, setSelectedItem] = useState(null);
  
    const menuItems = [
      { id: 1, title: 'University of Waterloo', time: 'September 2023 - 5 Years', position: 'Software Engineering', link: 'https://uwaterloo.ca/future-students/courses/software-engineering', details: ['Currently pursuing the undergraduate Bachelor of Software Engineering degree.', 'Term 2: Software Engineering (transfer).', 'Term 1: Computer Engineering - 86% average.'] },
      { id: 2, title: 'St. Robert High School', time: 'October 2019 - 4 Years', position: 'IB Diploma Program', link: 'https://www.ibo.org/en/school/001405', details: ['Final IB Score: 33.', 'Honor roll every year.'] },
      { id: 3, title: 'Kaggle (NLP)', time: 'July 2021', position: 'Online Certifications', link: 'https://www.kaggle.com/learn/certification/koralkulacoglu/natural-language-processing', details: ['Natural Language Processing.'] },
      { id: 4, title: 'Kaggle (Deep Learning)', time: 'July 2021', position: 'Online Certifications', link: 'https://www.kaggle.com/learn/certification/koralkulacoglu/intro-to-deep-learning', details: ['Intro to Deep Learning.'] },
      { id: 5, title: 'Kaggle (ML)', time: 'July 2021', position: 'Online Certifications', link: 'https://www.kaggle.com/learn/certification/koralkulacoglu/intro-to-machine-learning', details: ['Intro to Machine Learning.'] },
      { id: 6, title: 'Udemy (Quantum Computing)', time: 'July 2021', position: 'Online Certifications', link: 'https://www.udemy.com/certificate/UC-e089c8ce-23ee-4eda-8abe-4d8423a9fd86/', details: ['Quantum Computing.'] },
      { id: 7, title: 'Udemy (Python)', time: 'August 2020', position: 'Online Certifications', link: 'https://www.udemy.com/certificate/UC-26f4bb2c-a1d1-4ea6-8e52-3cb5aea5aa7d/', details: ['Complete Python Bootcamp.'] },
      { id: 8, title: 'freeCodeCamp (Web Dev)', time: 'August 2020', position: 'Online Certifications', link: 'https://www.freecodecamp.org/certification/fcc47576664-93c3-4618-9c6b-ad58363a017e/responsive-web-design', details: ['Responsive Web Design.'] },
      { id: 9, title: 'freeCodeCamp (ML)', time: 'June 2021', position: 'Online Certifications', link: 'https://www.freecodecamp.org/certification/fcc47576664-93c3-4618-9c6b-ad58363a017e/machine-learning-with-python-v7', details: ['Machine Learning with Python.'] },
      { id: 10, title: 'freeCodeCamp (JavaScript)', time: 'June 2021', position: 'Online Certifications', link: 'https://www.freecodecamp.org/certification/fcc47576664-93c3-4618-9c6b-ad58363a017e/javascript-algorithms-and-data-structures', details: ['JavaScript Algorithms and Data Structures.'] },
      { id: 11, title: 'freeCodeCamp (Python)', time: 'June 2021', position: 'Online Certifications', link: 'https://www.freecodecamp.org/certification/fcc47576664-93c3-4618-9c6b-ad58363a017e/scientific-computing-with-python-v7', details: ['Scientific Computing with Python.'] },
    ];
  
    const handleClick = (id) => {
      setSelectedItem(id === selectedItem ? null : id);
    };

    return (
        <div className="education reveal" id="education">
            <div className="title"><p>4.</p>Education</div>
            <p className="small__text">I am currently at the University of Waterloo pursuing a Bachelor's degree with a major in <a className="link__text" href="https://uwaterloo.ca/future-students/courses/software-engineering" target="_blank" rel="noreferrer">Software Engineering</a>.</p>
            <div className="work-experience-container">
                <div className="menu-and-details">
                <ul className="menu">
                    {menuItems.map((item) => (
                    <li key={item.id} onClick={() => handleClick(item.id)}>
                        {item.title}
                    </li>
                    ))}
                </ul>
                <div className="details">
                    <div class="job__title">
                        {selectedItem !== null && (
                        <p>{menuItems.find((item) => item.id === selectedItem).position} at <a className="link__text" href={menuItems.find((item) => item.id === selectedItem).link} target="_blank" rel="noreferrer">{menuItems.find((item) => item.id === selectedItem).title}</a></p>
                        )}
                    </div>

                    <div class="job__text">
                        {selectedItem !== null && (
                        <p>{menuItems.find((item) => item.id === selectedItem).time}</p>
                        )}
                        &nbsp;

                        {selectedItem !== null && (
                            <ul>
                                {menuItems
                                    .find((item) => item.id === selectedItem)
                                    .details.map((detail, index) => (
                                    <li key={index} style={{ listStyle: 'none' }}> <span style={{ color: '#64ffda' }}>▷</span> {detail}
                                    </li>
                                    ))}
                            </ul>
                        )}
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default Education;