import React from "react";

function Navbar() {
  return (
    <div class="nav">
      <input type="checkbox" id="nav-check"></input>
      <div class="nav-header">
        <a class="nav-title right__animation" href="#top">
          Koral
        </a>
      </div>
      <div class="nav-btn left__animation">
        <label for="nav-check">
          <span class="turn-invis"></span>
          <span></span>
          <span class="turn-invis"></span>
        </label>
      </div>

      <div class="nav-links left__animation">
        <p>1.</p>
        <a href="#about">About</a>
        <p>2.</p>
        <a href="#experience">Experience</a>
        <p>3.</p>
        <a href="#projects">Projects</a>
        <p>4.</p>
        <a href="#education">Education</a>
        <p>5.</p>
        <a href="#publications">Publications</a>
        <a
          href="https://drive.google.com/file/d/16tc2ss7FAvnHilP-DuXDzPt13aUVkH04/view"
          target="_blank"
          rel="noreferrer"
          class="resume"
        >
          Resume
        </a>
      </div>
    </div>
  );
}

export default Navbar;
