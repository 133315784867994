import React, { useState } from "react";
import Data from "./extra/Data";
import Card from "./extra/Card";
import Buttons from "./extra/Buttons";

function Projects() {
    const [item, setItem] = useState(Data);

    const menuItems = [...new Set(Data.flatMap((Val) => Val.category))];
  
    const filterItem = (curcat) => {
      const newItem = Data.filter((newVal) => {
        return newVal.category.includes(curcat);
      });
      setItem(newItem);
    };

    return (
        <div class='projects reveal' id='projects'>
            <div className="title"><p>3.</p>My Projects</div>
            <p className="small__text">Below are some of my projects. You can filter projects by topic, or find more projects on my <a className="link__text" href="https://github.com/KoralK5" target="_blank" rel="noreferrer">GitHub</a>.</p>
            <Buttons filterItem={filterItem} setItem={setItem} menuItems={menuItems}/>
            <Card item={item}/>
        </div>
    )
}

export default Projects