import React from 'react';
import Navbar from './pages/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Projects from './pages/Projects';
import Education from './pages/Education';
import Publications from './pages/Publications';
import Experience from './pages/Experience';
import './App.css';

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 50;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

function App() {
  let prevScrollPos = window.pageYOffset;

  window.onscroll = function() {
    let currentScrollPos = window.pageYOffset;

    let screenGood = document.querySelector("#nav-check").checked;

    if (screenGood || prevScrollPos > currentScrollPos) {
      // Scrolling up, show the navbar
      document.querySelector(".nav").style.top = "0";
    }
    else if (currentScrollPos > 20) {
      // Scrolling down, hide the navbar
      document.querySelector(".nav").style.top = "-100px"; // Adjust the value based on your navbar height
    }

    prevScrollPos = currentScrollPos;
  };

  window.addEventListener("scroll", reveal);

  return (
    <div className="App">
      <div>
          <Home/>
          <About/>
          <Experience/>
          <Projects/>
          <Education/>
          <Publications/>
          <Navbar/>
      </div>
        <footer className="text-center text-gray-600">&copy; Built by Koral Kulacoglu</footer>
    </div>
  );
}

export default App;
