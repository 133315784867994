const Data = [
  {
    id: "1",
    title: "Admini",
    category: ["Artificial Intelligence", "Frontend Dev", "Backend Dev"],
    tech: "AWS, EC2, DynamoDB, S3, React, Python, JavaScript, TypeScript",
    img: "https://i.ibb.co/p24YDsL/Landing-page.png",
    desc: "Admini is a centralized Canadian government contracting platform and startup that I founded to streamline the process of finding and managing government contracts. I'm currently leading a five-person software team and overseeing the use of AWS for scalable infrastructure, React for building an intuitive user interface, and web scrapers to automate and populate our EC2 vector databases with contract data. We're currently focused on enhancing search capabilities with AI-driven improvements.",
    link: "http://admini.ca",
    git: "https://github.com/admini-ca",
  },
  {
    id: "2",
    title: "FourSight",
    category: ["Artificial Intelligence", "Frontend Dev", "Backend Dev"],
    tech: "Python, JavaScript, TensorFlow, MatPlotLib, Django, Docker, Heroku, Pandas",
    img: "https://i.ibb.co/xg7f8B6/foursight.png",
    desc: "This is my science fair project where I attempted to revolutionalize cancer diagnosis by combining two of science's newest innovations: AI and genetic sequencing. A multilayer perceptron neural network was created and trained using TensorFlow until it reached a 95% testing accuracy. A statistical analysis was also performed using MatPlotLib and Pandas to find genes that could act as biomarkers for different types of cancer. Finally, I created a web app using Django and Docker to allow users to upload their genetic data and receive a cancer diagnosis. The web app was deployed using Heroku.",
    link: "https://projectboard.world/ysc2022/project/foursight-analysis-of-cancerous-genetic-profiles-with-artificial-neural-networks",
    git: "https://github.com/redmac135/cwsf2022",
  },
  {
    id: "3",
    title: "PrsntAI",
    category: ["Artificial Intelligence", "Frontend Dev", "Backend Dev"],
    tech: "Python, JavaScript, GPT-3.5, DALL-E, React, Django, Flask, Vercel",
    img: "https://i.ibb.co/Qk350xP/kitty.png",
    desc: "PrsntAI is a startup I founded that allows people to transform their speech into presentation slides live using AI. For this project, I fine-tuned OpenAI's GPT-3.5 model to convert a presentation script to a presentation slide format with a title, text, bullet points, and image descriptions. Then I used OpenAI's DALL-E to convert the image descriptions to actual images. I implemented React Speech Recognition to receive live audio input from the user, and combined it with my fine-tuned model. Finally, I created 3 of the same website using Flask, Django, and React. The website was deployed to prsntai.com using Vercel.",
    link: "http://prsntai.com",
    git: "https://github.com/prsntai",
  },
  {
    id: "4",
    title: "Auto",
    category: ["Artificial Intelligence", "Computer Vision"],
    tech: "Python, OpenCV, NumPy, YOLOv4-tiny",
    img: "https://i.ibb.co/gtTQf1v/Auto.gif",
    desc: "This is a project I did for fun where I created a basic self-driving car software using OpenCV and NumPy. The program is able to detect lanes using canny line detection and has stable steering implemented. It can also detect objects like cars and traffic signs using the YOLOv4-tiny model.",
    link: "https://www.youtube.com/watch?v=I9cR4of2jlo&list=PL4iMkUwfSFa3LzvXLPlDLKk0EshU3x4gV",
    git: "https://github.com/KoralK5/Auto",
  },
  {
    id: "5",
    title: "AirBoard",
    category: ["Artificial Intelligence", "Computer Vision"],
    tech: "Python, OpenCV, MediaPipe",
    img: "https://user-images.githubusercontent.com/62809012/120325744-f5953e80-c2b5-11eb-85b2-715d1f2b1fef.gif",
    desc: "For this 24-hour project, I created a program that allows users to type without a keyboard. The program uses MediaPipe to detect hand landmarks, and then uses the coordinates of the landmarks to control the mouse and keyboard.",
    git: "https://github.com/KoralK5/AirBoard",
  },
  {
    id: "6",
    title: "Songsnap",
    category: [
      "Artificial Intelligence",
      "Frontend Dev",
      "Backend Dev",
      "Computer Vision",
      "Games",
    ],
    tech: "Python, GPT-4 Vision, GPT-3.5, Cohere, PostgreSQL, Spotify API, Auth0, Flask",
    img: "https://d112y698adiu2z.cloudfront.net/photos/production/software_photos/002/738/945/datas/original.PNG",
    desc: "This is my 2024 UofTHacks project. Our website Songsnap is able to input in photos and generate entire Spotify playlists that encapsulate the moment. It utilizes GPT-4 Vision to extract detailed information from images which then get summarized using Cohere. Finally, a fine-tuned GPT-3.5 model curates a Spotify playlist using this information for the user to enjoy. Additionally, users can manage and access their playlists through a library page powered by Supabase, a PostgreSQL database API and our Auth0 login system.",
    link: "https://devpost.com/software/songsnap-p10xbc",
    git: "https://github.com/SubwayMan/songsnap",
  },
  {
    id: "7",
    title: "NoteBot Bryan",
    category: ["Artificial Intelligence", "Computer Vision", "Games"],
    tech: "Python, GPT-3.5, Whisper, PyGame, PyAudio, Threading",
    img: "https://i.ibb.co/pyP5QmG/bryan.jpg",
    desc: "This is my 2024 DeltaHacks project. Bryan is an AI assistant that can listen to your professor and take notes in class automatically. We began this hackathon project by using PyAudio and OpenAI's Whisper model to capture and filter out background noise to generate a real-time transcript of the audio. After that, we fine-tuned GPT-3.5 to convert transcribed speech to markdown note format. Finally, we integrated these components into a PyGame UI using multithreading.",
    link: "https://devpost.com/software/bryan",
    git: "https://github.com/romistaro/NoteBotBryan",
  },
  {
    id: "8",
    title: "Cancer Detection with Optimized NNs",
    category: ["Artificial Intelligence", "Frontend Dev"],
    tech: "Python, JavaScript, TensorFlow, MatPlotLib, Flask, NumPy",
    img: "https://firebasestorage.googleapis.com/v0/b/project-leo-mvp.appspot.com/o/attachments%2Fa8eeff00-49ef-417f-b089-785caa9c4ab5%2F7a1bdca2971?alt=media&token=4c94a8e5-4886-4aa3-93ac-714d91966be6",
    desc: "For this science fair project, my partner and I created an artificial neural network from scratch to detect malignancy on histopathological images of lymph tissue as well as leukemia from microscopic blood images. We then made a website with Flask. The website allows users to upload multiple medical images and receive a cancer diagnosis.",
    link: "https://projectboard.world/ysc2021/project/cancer-detection-with-optimized-neural-networks",
    git: "https://github.com/KoralK5/CWSF2021",
  },
  {
    id: "9",
    title: "COVID 2008 Sim",
    category: ["Artificial Intelligence", "Games"],
    tech: "Python, PyGame, NumPy",
    img: "https://d112y698adiu2z.cloudfront.net/photos/production/software_photos/001/747/887/datas/original.png",
    desc: "This is a COVID-19 spread simulation I made for a hackathon. It uses 2008 taxi data in Beijing. The PyGame simulation uses a distance graph algorithm we created from scratch to simulate the spread of COVID-19.",
    link: "https://devpost.com/software/covid-2008-sim",
    git: "https://github.com/KoralK5/COVIDTracker",
  },
  {
    id: "10",
    title: "Space Cruiser",
    category: ["Games"],
    tech: "Java, JavaFX, Swing",
    img: "https://user-images.githubusercontent.com/62809012/127981636-02ff1b8a-a148-4436-8ce2-35cca33f3430.gif",
    desc: "This is a Java game I made for fun. It is a 2D space shooter game where the player must shoot down enemy ships and avoid asteroids. The game uses JavaFX and Swing for the GUI.",
    git: "https://github.com/KoralK5/SpaceCruiser",
  },
];

export default Data;
