import React from "react";
import Data from "./Data";

const Buttons = ({ filterItem, setItem, menuItems }) => {
  return (
    <>
      <div className="buttons">
        <button className="buttons__item" onClick={() => setItem(Data)}>All</button>
        {menuItems.map((Val, id) => {
          return (
            <button className="buttons__item" onClick={() => filterItem(Val)} key={id}>{Val}</button>
          );
        })}
      </div>
    </>
  );
};

export default Buttons;