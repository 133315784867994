import React from "react";
import { FaGithub, FaLink } from "react-icons/fa";

const Card = ({ item }) => {
  return (
        <div className="project__container">
          {item.map((Val) => {
            return (
              <div className="project__item" key={Val.id}>
                <img src={Val.img} alt={Val.title} className="project__photo"/>
                <div className="project__body">
                  <div className="project__split">
                    <div className="project__title">{Val.title}</div>
                    <div className="project__links">
                      <a href={Val.link} target="_blank" rel="noreferrer" className="project__link"><FaLink/></a>
                      <a href={Val.git} target="_blank" rel="noreferrer" className="project__link"><FaGithub/></a>
                    </div>
                  </div>
                  <div className="project__tech">{Val.tech}</div>
                  <div className="project__text">{Val.desc}</div>
                </div>
              </div>
            );
          })}
        </div>
  );
};

export default Card;