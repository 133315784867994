import React, { useState } from "react";

const Experience = () => {
  const [selectedItem, setSelectedItem] = useState(null);

  const menuItems = [
    {
      id: 1,
      title: "Admini",
      time: "August 2024 - Ongoing",
      position: "Co-Founder & Lead Software Engineer",
      link: "https://admini.ca",
      details: [
        "Led a 5-person software team to build a centralized government contracting platform.",
        "Developed and populated S3 and an EC2 vector database using web scrapers for optimized contract search.",
        "Designed and led the development of the React frontend and AWS Lambda API layer.",
      ],
    },
    {
      id: 2,
      title: "D2L",
      time: "May 2024 - 4 Months",
      position: "Full-Stack Developer",
      link: "https://www.d2l.com/labs",
      details: [
        "Utilized Meta FAISS to generate knowledge graphs and vector databases of Brightspace courses.",
        "Created custom RAG models using AWS Bedrock and developed a React.js frontend to host them.",
        "Worked on a Node.js backend API layer that interacts with AWS services (Lambda, S3, DynamoDB, Step Functions...).",
      ],
    },
    {
      id: 3,
      title: "Unitic",
      time: "March 2020 - 1 Year",
      position: "Backend Developer",
      link: "https://github.com/Uniticc",
      details: [
        "Built a TensorFlow neural network to generate therapy plans for Tourette syndrome.",
        "Created a database to hold user data with the Deta API.",
        "Developed Python scripts to automate HTML code formatting for diagnostic figures.",
      ],
    },
    {
      id: 4,
      title: "STR Coding Club",
      time: "October 2021 - 2 Years",
      position: "Vice President",
      link: "https://github.com/STR-Coding-Club",
      details: [
        "Lead a team of club executives to provide weekly lessons to 150 members and planned club activities.",
        "Taught members lessons on competitive programming with C++/Java/Python.",
        "Created and taught a deep learning curriculum to members.",
      ],
    },
    {
      id: 5,
      title: "Thornhill CC",
      time: "July 2020 - 2 Weeks",
      position: "Camp Counsellor",
      link: "https://www.markham.ca/wps/portal/home/recreation/community-fitness-centres/thornhill-cc-library/thornhill-community-centre-lib",
      details: [
        "Learned how to properly resolve conflicts in a workspace.",
        "Planned and formulated engaging activities for youth campers.",
        "Co-lead groups of up to 30 campers.",
      ],
    },
  ];

  const handleClick = (id) => {
    setSelectedItem(id === selectedItem ? null : id);
  };

  return (
    <div className="experience reveal" id="experience">
      <div className="title">
        <p>2.</p>Work Experience
      </div>
      <p class="small__text">
        Below are some of the places I have worked at. You can use the menu to
        see more details.
      </p>
      <div className="work-experience-container">
        <div className="menu-and-details">
          <ul className="menu">
            {menuItems.map((item) => (
              <li key={item.id} onClick={() => handleClick(item.id)}>
                {item.title}
              </li>
            ))}
          </ul>
          <div className="details">
            <div class="job__title">
              {selectedItem !== null && (
                <p>
                  {menuItems.find((item) => item.id === selectedItem).position}{" "}
                  at{" "}
                  <a
                    className="link__text"
                    href={
                      menuItems.find((item) => item.id === selectedItem).link
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    {menuItems.find((item) => item.id === selectedItem).title}
                  </a>
                </p>
              )}
            </div>

            <div class="job__text">
              {selectedItem !== null && (
                <p>{menuItems.find((item) => item.id === selectedItem).time}</p>
              )}
              &nbsp;
              {selectedItem !== null && (
                <ul>
                  {menuItems
                    .find((item) => item.id === selectedItem)
                    .details.map((detail, index) => (
                      <li key={index} style={{ listStyle: "none" }}>
                        {" "}
                        <span style={{ color: "#64ffda" }}>▷</span> {detail}
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
