import React from 'react'
import Typewriter from 'typewriter-effect';
import { FaGithub, FaLinkedin, FaEnvelope} from "react-icons/fa";

function Home() {
    return (
        <div class="text-center" id="home">
            <h1 class="page__title down__animation">Koral Kulacoglu</h1>
            <div className="socials">
                <a href="https://github.com/KoralK5" target="_blank" rel="noreferrer" class="socials__item right__animation"><FaGithub/></a>
                <a href="https://www.linkedin.com/in/koralkulacoglu/" target="_blank" rel="noreferrer" class="socials__item appear__animation"><FaLinkedin/></a>
                <a href="mailto://kulacoglukoral@gmail.com" target="_blank" rel="noreferrer" class="socials__item left__animation"><FaEnvelope/></a>
            </div>

            <div class="typewriter appear__animation">
                <Typewriter 
                    options={{
                    strings: ['Software Engineer', 'ML Developer', 'Full Stack Developer', 'Competitive Programmer'],
                    autoStart: true,
                    loop: true,
                    delay: 100,
                }}/>
            </div>
        </div>
    )
}

export default Home